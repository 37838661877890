import { Component, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbPanelChangeEvent, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Router } from '@angular/router';
import { LoginService } from '../../../services/authentication/login.service';
import { ApiService } from '../../../services/main/api.service';
import { Global } from '../../../services/global';
import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements AfterViewInit {
    @Output() toggleSidebar = new EventEmitter<void>();

    public user: any;

    public config: PerfectScrollbarConfigInterface = {};

    public showSearch = false;

    private url: any;
    public profile_url;
    public teams:any;
    public curr_team:any;

    constructor(
        private modalService: NgbModal,
        private _router: Router,
        public _login: LoginService,
        public srv: ApiService
    ) {
        this.user = {name:''};
        this.url = Global.url;
        this.teams = [];
        this.curr_team = {id:0, description:''};

        _login.getInfo().then(x => {
            this.user.name = x;
            
            /*this.getProfilePic(x['id']).subscribe(y => {
                if( y != false ){
                    this.profile_url = y;
                }
            });

            _login.getSystemInfo().then(x => {
                this.teams = x.teams;
                this._login.getTeam().then(t => {
                    this.curr_team = this.getTeam(t);
                });
            });*/
        });

        /*srv.reload_pic.subscribe(val => {
            if( val ){
                this.getProfilePic(this.user['id']).subscribe(x => {
                    if( x != false ){
                        this.profile_url = x;
                    }
                });
            }
        });*/
    }

    ngAfterViewInit() {}

    public logout() {
      //localStorage.clear();
      this._login.logout().then(x => {
          this._router.navigate(['authentication/login']);
      });
    }

    public getProfilePic(id:any){
        var qst = 'profilepic/' + id;

        return this.srv.getCall(qst).pipe(
                    mergeMap(x => {
                        if(x.status == true ){
                          return of(x.data.rows);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      catchError(data => of(false))
                );
    }

    public getTeam(id:any){
        let resp:any;
        resp = {};

        let tmp = this.teams.filter(t => {
            return t.id == id;
        });

        if( tmp.length > 0 ){
            resp = tmp[0];
        }

        return resp;
    }

    public changeTeam(obj:any){
        if( obj.id == this.curr_team.id ){
            return;
        }

        this.curr_team = obj;
        this._login.setTeam(obj.id).then(() => {
            this.srv.team_changed.next(true);
        });
    }

}
