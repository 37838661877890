import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/authentication/login.service';
import { ApiService } from '../../services/main/api.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Observable, BehaviorSubject, of, throwError, from, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-step5',
  templateUrl: './step5.component.html',
  styleUrls: ['./step5.component.css']
})
export class Step5Component implements OnInit {

    momentjs: any = moment;

    appcode:any;
    codefam:any;
    period:any;
    filters:any = {};

    friends:any = [];
    //friends_obj:any = {};
    friends_obj:any;
    quiz:any = [];

    max_friends = 4;
    min_friends = 0;

    saving = false;
    loading = false;
    edited = false;

    constructor(
          public _login: LoginService,
          public _router: Router,
          public api: ApiService,
          private storage: Storage
      ) {
          
      }

    async ngOnInit() {
        window.scroll({ 
                   top: 0, 
                   left: 0, 
                   behavior: 'smooth' 
                });

        this._login.getCommonData().then(rs => {
            this.filters = rs;
        });

        var promises = [
            this.storage.get('appcode'),
            this.storage.get('period'),
            this.storage.get('codefam'),
        ];

        await Promise.all(promises).then(t => {
            this.period = t[1];

            if( t[0] ){
                this.appcode = t[0];
            }

            if( t[2] ){
                this.codefam = t[2];
            }
        });

        //fill at least one sibling
        //this.addFriend();

        this.getInfo(this.appcode, this.codefam);
        
        //to avoid page change without saving
        this.edited = true;
    }

    addFriend() {
      if( this.friends.length >= this.max_friends ){
        return;
      }

      this.friends.push({name:'', course:''});
    }

    remove(i:number){
        if( this.friends.length <= this.min_friends ){
        return;
      }

      this.friends.splice(i, 1);
    }

    validatefriends(){
        var msg = '';

        try{

          this.friends.forEach(value => {
              if(value.name == undefined || (value.name == '') ){
                throw 'Especifique nombre del amigo/conocido';
              }

              if(value.course == undefined || (value.course == '') ){
                throw 'Especifique curso del amigo/conocido';
              }
          });
        }
        catch( err ){
          msg = err;
        }

        return msg;
    }

    getInfo(appcode:any, codefam:any){
        if( appcode && codefam ){
            //appcode exist so try to get info
            this.loading = true;
            this.getDataFromServer(appcode, codefam).subscribe(x => {
                this.loading = false;
                //console.log(x);
                this.parseData(x);
            });
        }
    }

    getDataFromServer(appcode:any, codefam:any){
        var par_url = 'admission/step5';

        return this.api.getCallNoAuth(par_url, {code:appcode, codefam:codefam, period: this.period}).pipe(
                    mergeMap(data => {
                        let mydata:any = {status: false, message:'', data:[]};
                        mydata = data;
                        
                        if( mydata.status ){
                            return of(mydata.data);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    sendDataToServer(obj:any){
        var par_url = 'admission/step5';
        //console.dir(obj);return;

        return this.api.postCallNoAuth(par_url, obj).pipe(
                    mergeMap(data => {
                        let mydata:any = {status: false, message:'', data:[]};
                        mydata = data;
                        //console.dir(mydata);
                        if( mydata.status ){
                            return of(true);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    parseData(data:any){
        //console.log(data);
        if( data ){
            if( data['friends'] ){
                //this.friends = data['friends'];
                if(data['friends']){
                    this.friends_obj = data['friends'];//console.dir(this.friends_obj);
                    this.friends = this.parseFriends(data['friends']['resp']);
                }
            }

            if( Array.isArray(data['quiz']) ){
                //this.quiz = data['quiz'];
                this.quiz = data['quiz'].filter(q => q.pos > 1);
            }
        }
    }

    parseFriends(str:string){
        var items = [];
        var tmp = str.split(';');
        var inner = [];

        tmp.forEach( val => {
            inner = val.split('|');
            if( (inner[0] != undefined) && inner[1] != undefined ){
                items.push({course:inner[1], name:inner[0]});
            }
        });
        
        return items;
    }

    saveAndNext(){
        let friends_str = '';

        if( this.friends.length > 0 ){
            var err = this.validatefriends();
            if( err != '' ){
                this.showError(err);
                return;
            }

            var temp = [];
            this.friends.forEach(val => {
                temp.push(val.name + '|' + val.course);
            });

            friends_str = temp.join(';');
        }

        let obj:any = {};

        if( this.appcode ){
            obj.code = this.appcode;
        }

        if( this.codefam ){
            obj.codefam = this.codefam;
        }

        if( this.friends_obj ){
            this.friends_obj.resp = friends_str;
            obj.friends = [this.friends_obj];
        }
        
        obj.quiz = this.quiz;
        obj.period = this.period;
        
        this.saving = true;

        this.sendDataToServer(obj).subscribe(x => {
            this.saving = false;
            //console.dir(x);

            if( x != false ){
                this.saving = false;
                this._router.navigate(['/paso_6']);
            }
            else{
                Swal.fire('Error al guardar información', '', 'error');
            }
        });
    }

    showError(msg:string){
        Swal.fire(msg, '', 'error');
    }

}
