import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/authentication/login.service';
import { ApiService } from '../../services/main/api.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Observable, BehaviorSubject, of, throwError, from, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-colaborators',
  templateUrl: './colaborators.component.html',
  styleUrls: ['./colaborators.component.css']
})
export class ColaboratorsComponent implements OnInit {
    momentjs: any = moment;

  	appcode:any;
    period:any;
    alu:any = {};
    filters:any = {};
    max_items = 3;
    saving = false;
    loading = false;
    edited = false;
    //hasEdu:any = false;
    hasEdu:any = 'N';
    //course id from which education is required
    ref_course = 3;

    //upload error handling
    upload_errors:any = [];

	constructor(
        public _login: LoginService,
        public _router: Router,
        public api: ApiService,
        private storage: Storage
    ) {
        this.alu.education = [];
        this.upload_errors = [];
    }

	async ngOnInit() {
        window.scroll({ 
                   top: 0, 
                   left: 0, 
                   behavior: 'smooth' 
                });

        this._login.getCommonData().then(rs => {
            this.filters = rs;
            //console.dir(rs);
        });

        var promises = [
            this.storage.get('appcode'),
            this.storage.get('period')
        ];

        await Promise.all(promises).then(t => {
            this.period = t[1];

            if( t[0] ){
                this.appcode = t[0];
            }
        });

        this.getInfo(this.appcode);
        
        //to avoid page change without saving
        this.edited = true;
	}

    courseChanged(cur:any){
        //console.log(cur);
        //selected course do not need to enter education
        if( parseInt(cur) < this.ref_course ){
            this.alu.education = [];
            this.hasEdu = 'N';
            return;
        }

        let res = parseInt(cur) - 1;
        let max = (res > 0) ? res : 0;
        max = (max > this.max_items) ? this.max_items : max;

        if( Array.isArray(this.alu.education) && (this.alu.education.length >= 0) ){
            let missing = max - this.alu.education.length;
            for( let i=1; i<=missing; i++ ){
                this.addEducation();
            }
        }
        else{
            this.alu.education = [];
            for( let i=1; i<=max; i++ ){
                this.addEducation();
            }
        }

        //console.log(this.alu);
    }

    validateEducationCourse(cur:any){
        let resp = true;
        let res = parseInt(cur) - 1;
        let max = (res > 0) ? res : 0;
        max = (max > this.max_items) ? this.max_items : max;

        if( (this.alu.education.length > 0) && (this.alu.education.length < max) ){
            resp = false;
        }

        return resp;
    }

    addEducation() {
        let obj = {id:0, course:'', school:'', year:'', avg:''};

        if( this.alu.education.length >= this.max_items ){
            return;
        }

        this.alu.education.push(obj);
    }

    delEducation() {
        this.alu.education.pop();
    }

    validateInfo(fields: any) {
        let mygroup: any = {};
        mygroup = new FormGroup({
            'type_id': new FormControl(fields.type_id, [Validators.required]),
            //'doc_id': new FormControl(fields.doc_id, [Validators.required, Validators.pattern('[0-9]*')]),
            'doc_id': new FormControl(fields.doc_id, [Validators.required]),
            'sex': new FormControl(fields.sex, [Validators.required]),
            'lastname1': new FormControl(fields.lastname1, [Validators.required]),
            'lastname2': new FormControl(fields.lastname2, [Validators.required]),
            'name': new FormControl(fields.name, [Validators.required]),
            'dob': new FormControl(fields.dob, [Validators.required]),
            'country': new FormControl(fields.country, [Validators.required]),
            'city': new FormControl(fields.city, [Validators.required]),
            'insti': new FormControl(fields.insti, [Validators.required]),
            'course': new FormControl(fields.course, [Validators.required]),
            'period': new FormControl(fields.period, [Validators.required]),
            //'fam_copece': new FormControl(fields.fam_copece, [Validators.required])
        });

        return mygroup.valid;
    }

    validateEducation(fields: any) {
        let mygroup: any = {};
        mygroup = new FormGroup({
            'course': new FormControl(fields.course, [Validators.required]),
            'school': new FormControl(fields.school, [Validators.required]),
            'year': new FormControl(fields.year, [Validators.required, Validators.pattern('[0-9]*'), Validators.min(4)]),
            //'avg': new FormControl(fields.avg, [Validators.required, Validators.pattern('[0-9\.]*')])
            'avg': new FormControl(fields.avg, [Validators.required, Validators.pattern('[a-zA-Z0-9\.\+\-]*')])
        });

        return mygroup.valid;
    }

    handleInputChange(e, obj:any, ref:string, accepted:any) {
        //console.log(obj);
        //console.log(e);
        var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
        //console.dir(file);
        if( !file ){
            obj[ref] = null;
            return;
        }
        
        //validate accepted format
        let valid = false;
        if( Array.isArray(accepted) ){
            accepted.forEach(f => {
                var replace = "\." + f + "$";
                var re = new RegExp(replace,"ig");
                //console.dir(re);
                
                if( re.test(file.name) ){
                    valid = true;
                }
            });
        }

        if( !valid ){
            Swal.fire('Formato de imagen incorrecto', '', 'error');
            return;
        }

        obj[ref] = file;
        /*var reader = new FileReader();

        reader.onload = (_event) => { 
          obj[ref] = reader.result;
        }

        reader.readAsDataURL(file);*/
    }

    getInfo(appcode:any){
        if( appcode ){
            //appcode exist so try to get info
            this.loading = true;
            this.getDataFromServer(appcode, this.period).subscribe(x => {
                this.loading = false;
                //console.log(x);
                this.parseData(x);
                
                //fill education just in case
                this.courseChanged(this.alu['course']);
            });
        }
    }

    getDataFromServer(appcode:any, period:any){
        var par_url = 'admission/step1';

        return this.api.getCallNoAuth(par_url, {code:appcode, period:period}).pipe(
                    mergeMap(data => {
                        let mydata:any = {status: false, message:'', data:[]};
                        mydata = data;
                        
                        if( mydata.status ){
                            return of(mydata.data);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    sendDataToServer(obj:any){
        var par_url = 'admission/step1';

        return this.api.postCallNoAuth(par_url, obj).pipe(
                    mergeMap(data => {
                        let mydata:any = {status: false, message:'', data:[]};
                        mydata = data;
                        
                        /*if( mydata.status ){
                            return of(mydata.data);
                        }
                        else{
                          return of(false);
                        }*/

                        return of(mydata);
                      }),
                      //timeout(MAX_TIME),
                      //catchError(data => of(false))
                      catchError(data => of({status: false, message:'Error al guardar información', data:[]}))
                );
    }

    parseData(data:any){
        if( data ){
            var tmp = {
                'type_id': data['data']['type_id'],
                'doc_id': data['data']['doc_id'],
                'sex': data['data']['sex'],
                'lastname1': data['data']['lastname1'],
                'lastname2': data['data']['lastname2'],
                'name': data['data']['name'],
                'dob': this.momentjs(data['data']['dob']).format('YYYY-MM-DD'),
                'country': data['data']['country'],
                'city': data['data']['city'],
                'insti': this.getInsti(data['data']['course']),
                'course': data['data']['course'],
                //'period': this.period,
                'period': (this.period) ? this.period : data['data']['period'],
                'school_motivo': data['data']['school_motivo'],
                'school_repetido': data['data']['school_repetido'],
                'schedule': data['data']['schedule']
            };

            this.alu = {...this.alu, ...tmp};

            //education list
            this.alu.education = [];
            if( Array.isArray(data['education']) && (data['education'].length > 0) && (parseInt(data['data']['course']) >= this.ref_course) ){
                data['education'].forEach(e => {
                    //let obj = {id:e.id, course:e.course, school:e.school, year:e.year, avg:e.avg};
                    this.alu.education.push(e);
                });
            }

            this.hasEdu = ((this.alu.education.length > 0) && (parseInt(data['data']['course']) >= this.ref_course)) ? 'S' : 'N';

            //images
            if(this.appcode){
                this.alu['profile_url'] = this.api.getBaseURL() + "admission/step1/profile?code=" + this.appcode;
                this.alu['idform_url'] = this.api.getBaseURL() + "admission/step1/myidform?code=" + this.appcode;
                this.alu['grades_url'] = this.api.getBaseURL() + "admission/step1/mygradeform?code=" + this.appcode;
            }
        }
    }

    getInsti(course:any){
        var resp = '';
        if( course < 2 ){
            resp = '1';
        }
        else{
            resp = '2';
        }
        return resp;
    }

    saveAndNext(){
        //console.log(this.alu);
        this.upload_errors = [];

        let validated = this.validateInfo(this.alu);
        if (!validated) {
            Swal.fire('Complete todos los campos requeridos correctamente', '', 'error');
            return;
        }

        if( !this.validateEducationCourse(this.alu.course) ){
            Swal.fire('Debe llenar "Historia Escolar"', '', 'error');
            return;
        }

        if( this.alu.education.length > 0 ){
            let val_edu:boolean;
            
            for( let item of this.alu.education ){
                val_edu = this.validateEducation(item);
                if( !val_edu ){
                    Swal.fire('Complete todos los campos de "Historia Escolar" correctamente', '', 'error');
                    return;
                }
            }
        }

        if( this.alu.course < -2 ){
            if( !this.alu.schedule ){
                Swal.fire('Debe seleccionar horario', '', 'error');
                return;
            }
        }
        else{
            //set default empty
            this.alu.schedule = '';
        }

        let obj:any = {};

        //student info
        obj.item = {...this.alu};

        obj.item.dob = this.momentjs(obj.item.dob).format('DD/MM/YYYY');
        obj.item.school = '';
        obj.item.school_add = '';
        obj.item.school_phone = '';
        obj.item.school_course = '';
        obj.item.xtra_school = '';

        if( this.appcode ){
            obj.item.code = this.appcode;
        }

        if(this.alu.education.length > 0){
            obj.education = this.alu.education;
        }

        this.saving = true;

        this.sendDataToServer(obj).subscribe(x => {
            this.saving = false;
            //console.dir(x);

            if( x.status != false ){
                let appcode = x.data['code'];

                var batch = [];
                batch.push(this.uploadProfilePic(appcode));
                batch.push(this.uploadId(appcode));
                batch.push(this.uploadGrades(appcode));

                this.saving = true;

                concat(...batch).subscribe({
                    complete: () => {
                        //this.saving = false;
                        // console.log('paso 1 completado');
                        // console.dir(this.upload_errors);

                        var promises = [
                            this.storage.set('appcode', appcode),
                            this.storage.set('period', this.alu.period)
                        ];

                        Promise.all(promises).then(x => {
                            this.saving = false;

                            if( this.upload_errors.length <= 0 ){
                                //next step only if there were no errors
                                this._router.navigate(['/paso_2']);
                            }
                        });
                    }
                });
            }
            else{
                if( x.message.Error != undefined ){
                    Swal.fire(x.message.Error.msg, '', 'error');
                }
                else{
                    Swal.fire('Error al guardar información', '', 'error');
                }
            }
        });
    }

    uploadProfilePic(appcode:string){
        //console.dir(this.alu['profileimg']);
        if( this.alu['profileimg'] ){
            var qst = 'admission/step1/profile?code=' + appcode;
            return this.uploadFile(qst, this.alu['profileimg'], 'foto alumno');
        }
        else{
            return of(false);
        }
    }

    uploadId(appcode:string){
        if( this.alu['imgdoc'] ){
            var qst = 'admission/step1/myidform?code=' + appcode;
            return this.uploadFile(qst, this.alu['imgdoc'], 'cedula');
        }
        else{
            return of(false);
        }
    }

    uploadGrades(appcode:string){
        if( this.alu['grades'] ){
            var qst = 'admission/step1/mygradeform?code=' + appcode;
            return this.uploadFile(qst, this.alu['grades'], 'libretas escolares');
        }
        else{
            return of(false);
        }
    }

    uploadFile(qst:string, file:any, t?:string){
        let formData = new FormData();
        formData.append("file", file);

        if( t == undefined ){
            t = '';
        }
        
        return this.api.customPostCallNoAuth(qst, formData).pipe(
                    mergeMap(data => {
                        let mydata:any = {status: false, message:'', data:[]};
                        mydata = data;
                        
                        if( mydata.status ){
                            return of(mydata.data);
                        }
                        else{
                            this.upload_errors.push('Archivo ' + t + ' presenta error en la carga, por favor revise el formato y tamaño e intente nuevamente');
                            return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    openFile(url:string){
        window.open(url);
    }

    validateID(event: any){
        /*let allowed:any = [];
        allowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

        if( allowed.indexOf(event.key) < 0 ){
            return false;
        }

        return true;*/
        return /^[a-z0-9]+$/i.test(event.key);
    }

}
