import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  // {
  //   path: '/home',
  //   title: 'Módulos',
  //   //icon: 'icon icon-grid',
  //   icon: 'fas fa-th-large',
  //   class: '',
  //   extralink: false,
  //   perm: 'home',
  //   submenu: []
  // },
  // {
  //   path: '/admins',
  //   title: 'General',
  //   icon: 'fas fa-user-cog',
  //   class: '',
  //   extralink: false,
  //   perm: 'ADMIN',
  //   submenu: []
  // },
  // {
  //   path: '/settings',
  //   title: 'Configuración',
  //   icon: 'fas fa-cog',
  //   class: '',
  //   extralink: false,
  //   perm: 'sett',
  //   submenu: []
  // }
  {
    path: '/home',
    title: 'Aspirante',
    icon: 'fas fa-user-graduate',
    class: '',
    extralink: false,
    perm: 'home',
    submenu: []
  },
  {
    path: '/paso_2',
    title: 'Familia',
    icon: 'fas fa-users',
    class: '',
    extralink: false,
    perm: 'UNIDADES_LISTA',
    submenu: []
  },
  {
    path: '/paso_3',
    title: 'Información Adicional',
    icon: 'fas fa-file-signature',
    class: '',
    extralink: false,
    perm: 'CONDUCTORES_LISTA',
    submenu: []
  },
  /*{
    path: '/home',
    title: 'Gasolineras',
    icon: 'fas fa-gas-pump',
    class: '',
    extralink: false,
    perm: 'home',
    submenu: []
  },*/
  {
    path: '/paso_4',
    title: 'Datos Familiares',
    icon: 'fas fa-address-card',
    class: '',
    extralink: false,
    perm: 'COMBUSTIBLE_LISTAR',
    submenu: []
  },
  {
    path: '/paso_5',
    title: 'Convivencia',
    icon: 'fas fa-child',
    class: '',
    extralink: false,
    perm: 'USUARIOS_LISTA',
    submenu: []
  },
  {
    path: '/paso_6',
    title: 'Referencias',
    icon: 'fas fa-people-arrows',
    class: '',
    extralink: false,
    perm: 'ROLES_LISTA',
    submenu: []
  },
  
];
