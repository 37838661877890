import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/authentication/login.service';
import { ApiService } from '../../services/main/api.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Observable, BehaviorSubject, of, throwError, from, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-step6',
  templateUrl: './step6.component.html',
  styleUrls: ['./step6.component.css']
})
export class Step6Component implements OnInit {

    momentjs: any = moment;

    appcode:any;
    codefam:any;
    period:any;
    filters:any = {};

    refs:any = [];
    upload_errors:any = [];

    max_refs = 6;
    min_refs = 0;

    saving = false;
    loading = false;
    edited = false;

    constructor(
          public _login: LoginService,
          public _router: Router,
          public api: ApiService,
          private storage: Storage
      ) {
          
      }

    async ngOnInit() {
        window.scroll({ 
                   top: 0, 
                   left: 0, 
                   behavior: 'smooth' 
                });

        this._login.getCommonData().then(rs => {
            this.filters = rs;
        });

        var promises = [
            this.storage.get('appcode'),
            this.storage.get('period'),
            this.storage.get('codefam'),
        ];

        await Promise.all(promises).then(t => {
            this.period = t[1];

            if( t[0] ){
                this.appcode = t[0];
            }

            if( t[2] ){
                this.codefam = t[2];
            }
        });

        //fill at least one sibling
        this.addRef();

        this.getInfo(this.appcode, this.codefam);
        
        //to avoid page change without saving
        this.edited = true;
    }

    addRef() {
      if( this.refs.length >= this.max_refs ){
        return;
      }

      this.refs.push({name:'', phone:'', relationship: '', comment: '', img: ''});
    }

    remove(id:any, i:number){
        if( id == undefined ){
          this.refs.splice(i, 1);
        }
        else{
            let qst = 'admission/recomendation/del';

            Swal.fire({
                title: '¿Esta seguro que desea eliminarlo?',
                text: `Si acepta se eliminará el registro`,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Eliminar'
            }).then((result) => {
                if (result.value) {
                    this.api.postCallNoAuth(qst, {id: id}).subscribe(x => {
                        if(x.status){
                            this.refs.splice(i, 1);
                            Swal.fire('¡Eliminado!', '', 'success');
                        }
                        else{
                            Swal.fire(x.message, '', 'error');
                        }
                    });
                }
            });
        }//end else
    }

    validaterefs(){
        var msg = '';
        let names = [];

        try{

          this.refs.forEach(value => {
              if(value.name == undefined || (value.name == '') ){
                throw 'Especifique nombre de la referencia';
              }

              if(value.phone == undefined || (value.phone == '') ){
                throw 'Especifique teléfono de la referencia';
              }

              if(value.relationship == undefined || (value.relationship == '') ){
                throw 'Especifique relación con la referencia';
              }

              //required only in creation.. so no id
              if( (value.id == undefined) || (value.id == null) || (value.id == 0) ){
                 if(value.img == undefined || (value.img == '') ){
                    throw 'Especifique foto de la referencia';
                  }
              }

              let str = value.name.trim();

              if( names.indexOf(str) < 0 ){
                 names.push(str);
              }
              else{
                 throw 'Nombre de las referencias no deben ser iguales';
              }
              
          });
        }
        catch( err ){
          msg = err;
        }

        return msg;
    }

    getInfo(appcode:any, codefam:any){
        if( appcode && codefam ){
            //appcode exist so try to get info
            this.loading = true;
            this.getDataFromServer(appcode, codefam).subscribe(x => {
                this.loading = false;
                //console.log(x);
                this.parseData(x);
            });
        }
    }

    getDataFromServer(appcode:any, codefam:any){
        var par_url = 'admission/step7';

        return this.api.getCallNoAuth(par_url, {code:appcode, codefam:codefam, period: this.period}).pipe(
                    mergeMap(data => {
                        let mydata:any = {status: false, message:'', data:[]};
                        mydata = data;
                        
                        if( mydata.status ){
                            return of(mydata.data);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    sendDataToServer(obj:any){
        var par_url = 'admission/step7';
        //console.dir(obj);return;
        let mydata:any = {status: false, message:'', data:[]};

        return this.api.postCallNoAuth(par_url, obj).pipe(
                    mergeMap(data => {
                        mydata = data;
                        //console.dir(mydata);
                        if( mydata.status ){
                            //return of(true);
                            return of(mydata);
                        }
                        else{
                          //return of(false);
                          this.upload_errors.push(mydata.message.Error.msg);
                          return of(mydata);
                        }
                      }),
                      //timeout(MAX_TIME),
                      //catchError(data => of(false))
                      catchError(data => of(mydata))
                );
    }

    parseData(data:any){
        //console.log(data);
        if( data ){
            if( Array.isArray(data['refs']) && (data['refs'].length > 0) ){
                this.refs = data['refs'];

                this.refs.forEach(r => {
                    r['img_url'] = this.api.getBaseURL() + "admission/step7/ref/"+r['name'].replace(/\s/g, "").trim()+"?code=" + this.appcode;
                });
            }
        }
    }

    saveAndNext(){
        this.upload_errors = [];
        
        if( this.refs.length > 0 ){
            var err = this.validaterefs();
            if( err != '' ){
                this.showError(err);
                return;
            }
        }

        var batch = [];
        var tmp:any = {};

        this.refs.forEach(x => {
            tmp = x;
            tmp.code = this.appcode;
            tmp.codefam = this.codefam;

            batch.push(this.uploadReference(tmp, ''));
        });

        //finish form
        let obj:any = {};

        if( this.appcode ){
            obj.code = this.appcode;
        }

        if( this.codefam ){
            obj.codefam = this.codefam;
        }

        obj.period = this.period;
        batch.push(this.sendDataToServer(obj));


        this.saving = true;

        concat(...batch).subscribe({
            complete: () => {
                this.saving = false;
                if( this.upload_errors.length <= 0 ){
                    Swal.fire('¡Finalizada exitosamente!', '', 'success');
                    this._login.logout().then(x => {
                        this._router.navigate(['authentication/login']);
                    });
                }
                else{
                    Swal.fire('Error al guardar información', '', 'error');
                }
            }
        });

        /*this.sendDataToServer(obj).subscribe(x => {
            this.saving = false;
            //console.dir(x);

            if( x.status != false ){
                this.saving = false;
                Swal.fire('¡Finalizada exitosamente!', '', 'success');
                this._login.logout().then(x => {
                    this._router.navigate(['authentication/login']);
                });
            }
            else{
                if( x.message['Error'] != undefined ){
                    Swal.fire(x.message['Error']['msg'], '', 'error');
                }
                else{
                    Swal.fire('Error al guardar información', '', 'error');
                }
            }
        });*/
    }

    showError(msg:string){
        Swal.fire(msg, '', 'error');
    }

    handleInputChange(e, obj:any, ref:string, accepted:any) {
        var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
        //console.dir(file);
        if( !file ){
            obj[ref] = null;
            return;
        }
        
        //validate accepted format
        let valid = false;
        if( Array.isArray(accepted) ){
            accepted.forEach(f => {
                var replace = "\." + f + "$";
                var re = new RegExp(replace,"ig");
                //console.dir(re);
                
                if( re.test(file.name) ){
                    valid = true;
                }
            });
        }

        if( !valid ){
            Swal.fire('Formato de imagen incorrecto', '', 'error');
            return;
        }

        obj[ref] = file;
    }

    uploadReference(obj:any, t?:string){
        var qst = 'admission/step7/ref';
        let formData = new FormData();

        if( (obj['id'] != undefined) && (obj['id'] != '') ){
            formData.append("id", obj['id']);
        }
        
        formData.append("code", obj['code']);
        formData.append("codefam", obj['codefam']);
        formData.append("name", obj['name']);
        formData.append("phone", obj['phone']);
        formData.append("relationship", obj['relationship']);
        formData.append("comment", obj['comment']);

        if( (obj['img'] != undefined) && (obj['img'] != '') ){
            formData.append("file", obj['img']);
        }

        if( t == undefined ){
            t = '';
        }
        
        return this.api.customPostCallNoAuth(qst, formData).pipe(
                    mergeMap(data => {
                        let mydata:any = {status: false, message:'', data:[]};
                        mydata = data;
                        
                        if( mydata.status ){
                            return of(mydata.data);
                        }
                        else{
                            this.upload_errors.push('Archivo ' + t + ' presenta error en la carga, por favor revise el formato y tamaño e intente nuevamente');
                            return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

}
