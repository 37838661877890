import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/authentication/login.service';
import { ApiService } from '../../services/main/api.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Observable, BehaviorSubject, of, throwError, from, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-step4',
  templateUrl: './step4.component.html',
  styleUrls: ['./step4.component.css']
})
export class Step4Component implements OnInit {

    momentjs: any = moment;

    appcode:any;
    codefam:any;
    period:any;
    filters:any = {};

    siblings:any = [];
    relatives:any = [];

    max_siblings = 6;
    min_siblings = 0;

    saving = false;
    loading = false;
    edited = false;

    constructor(
          public _login: LoginService,
          public _router: Router,
          public api: ApiService,
          private storage: Storage
      ) {
          
      }

    async ngOnInit() {
        window.scroll({ 
                   top: 0, 
                   left: 0, 
                   behavior: 'smooth' 
                });

        this._login.getCommonData().then(rs => {
            this.filters = rs;
        });

        var promises = [
            this.storage.get('appcode'),
            this.storage.get('period'),
            this.storage.get('codefam'),
        ];

        await Promise.all(promises).then(t => {
            this.period = t[1];

            if( t[0] ){
                this.appcode = t[0];
            }

            if( t[2] ){
                this.codefam = t[2];
            }
        });

        //fill at least one sibling
        this.addSibling();
        //fill at least one relative
        this.addRelative();

        this.getInfo(this.appcode, this.codefam);
        
        //to avoid page change without saving
        this.edited = true;
    }

    addSibling() {
      /*if( this.siblings.length >= this.max_siblings ){
        return;
      }*/

      this.siblings.push({lastname_1:'', lastname_2:'', name:'', sex:'F', dob:this.momentjs().format('YYYY-MM-DD'), school:'', school_name:'', course:'', instruction:'', exalum:'N'});
    }

    addRelative() {
      this.relatives.push({name:'', relationship:''});
    }

    remove(id:any, i:number){
        if( id == undefined ){
          this.siblings.splice(i, 1);
        }
        else{
            let qst = 'admission/sibling/del';

            Swal.fire({
                title: '¿Esta seguro que desea eliminarlo?',
                text: `Si acepta se eliminará el registro`,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Eliminar'
            }).then((result) => {
                if (result.value) {
                    this.api.postCallNoAuth(qst, {id: id}).subscribe(x => {
                        if(x.status){
                            this.siblings.splice(i, 1);
                            Swal.fire('¡Eliminado!', '', 'success');
                        }
                        else{
                            Swal.fire(x.message, '', 'error');
                        }
                    });
                }
            });
        }//end else
    }

    removeRelative(id:any, i:number){
        if( id == undefined ){
          this.relatives.splice(i, 1);
        }
        else{
            let qst = 'admission/relative/del';

            Swal.fire({
                title: '¿Esta seguro que desea eliminarlo?',
                text: `Si acepta se eliminará el registro`,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Eliminar'
            }).then((result) => {
                if (result.value) {
                    this.api.postCallNoAuth(qst, {id: id, code: this.appcode}).subscribe(x => {
                        if(x.status){
                            this.relatives.splice(i, 1);
                            Swal.fire('¡Eliminado!', '', 'success');
                        }
                        else{
                            Swal.fire(x.message, '', 'error');
                        }
                    });
                }
            });
        }//end else
    }

    validateSiblings(){
        var msg = '';

        try{

          this.siblings.forEach(value => {
              if(value.lastname_1 == undefined || (value.lastname_1 == '') ){
                throw 'Especifique Apellido Paterno del Hermano(a)';
              }

              if(value.lastname_2 == undefined || (value.lastname_2 == '') ){
                throw 'Especifique Apellido Materno del Hermano(a)';
              }

              if(value.name == undefined || (value.name == '') ){
                throw 'Especifique Nombres del Hermano(a)';
              }

              // if(value.instruction == undefined || (value.instruction == '') ){
              //   throw 'Especifique Instrucción del Hermano(a)';
              // }

              //if( (value.instruction != 'UN') && (value.instruction != 'PF') ){
              if( (value.instruction == 'PR') || (value.instruction == 'CO') ){
                    if(value.school == undefined || (value.school == '') ){
                      throw 'Especifique Institución a la que pertenece el Hermano(a)';
                    }

                    if( (value.school == 'OTR') && ( value.school_name == undefined || (value.school_name == '') ) ){
                      throw 'Especifique Nombre de Institución a la que pertenece el Hermano(a)';
                    }

                    if(value.course == undefined || (value.curse == '') ){
                      throw 'Especifique Grado/Curso a la que pertenece el Hermano(a)';
                    }
              }

          });
        }
        catch( err ){
          msg = err;
        }

        return msg;
    }

    validateRelatives(){
        var msg = '';

        try{
          this.relatives.forEach(value => {
              if(value.name == undefined || (value.name == '') ){
                throw 'Especifique Nombres del familiar';
              }

              if(value.relationship == undefined || (value.relationship == '') ){
                throw 'Especifique relación con el familiar';
              }

          });
        }
        catch( err ){
          msg = err;
        }

        return msg;
    }

    getInfo(appcode:any, codefam:any){
        if( appcode && codefam ){
            //appcode exist so try to get info
            this.loading = true;
            this.getDataFromServer(appcode, codefam).subscribe(x => {
                this.loading = false;
                //console.log(x);
                this.parseData(x);
            });
        }
    }

    getDataFromServer(appcode:any, codefam:any){
        var par_url = 'admission/step4';

        return this.api.getCallNoAuth(par_url, {code:appcode, codefam:codefam, period: this.period}).pipe(
                    mergeMap(data => {
                        let mydata:any = {status: false, message:'', data:[]};
                        mydata = data;
                        
                        if( mydata.status ){
                            return of(mydata.data);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    sendDataToServer(obj:any){
        var par_url = 'admission/step4';
        //console.dir(obj);return;

        return this.api.postCallNoAuth(par_url, obj).pipe(
                    mergeMap(data => {
                        let mydata:any = {status: false, message:'', data:[]};
                        mydata = data;
                        //console.dir(mydata);
                        if( mydata.status ){
                            return of(true);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    parseData(data:any){
        //console.log(data);
        if( data ){
            if( Array.isArray(data['siblings']) ){
                this.siblings = data['siblings'];
                this.siblings.forEach(s => {
                    s.dob = this.parseDate(s.dob);
                });

                this.relatives = data['relatives'];
            }
        }
    }

    saveAndNext(){

        if( this.siblings.length > 0 ){
            var err = this.validateSiblings();
            if( err != '' ){
              this.showError(err);
              return;
            }
        }

        if( this.relatives.length > 0 ){
            var err = this.validateRelatives();
            if( err != '' ){
              this.showError(err);
              return;
            }
        }

        let obj:any = {};

        if( this.appcode ){
            obj.code = this.appcode;
        }

        if( this.codefam ){
            obj.codefam = this.codefam;
        }

        obj.siblings = this.siblings;
        obj.relatives = this.relatives;
        obj.period = this.period;
        
        this.saving = true;

        this.sendDataToServer(obj).subscribe(x => {
            this.saving = false;
            //console.dir(x);

            if( x != false ){
                this.saving = false;
                this._router.navigate(['/paso_5']);
            }
            else{
                Swal.fire('Error al guardar información', '', 'error');
            }
        });
    }

    showError(msg:string){
        Swal.fire(msg, '', 'error');
    }

    parseDate(str:any){
        let resp = null;
        let obj = this.momentjs(str);

        if( obj.isValid() ){
            resp = obj.format('YYYY-MM-DD');
        }

        return resp;
    }

}
