import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/authentication/login.service';
import { ApiService } from '../../services/main/api.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Observable, BehaviorSubject, of, throwError, from, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-step3',
  templateUrl: './step3.component.html',
  styleUrls: ['./step3.component.css']
})
export class Step3Component implements OnInit {

    momentjs: any = moment;

    appcode:any;
    codefam:any;
    period:any;
    filters:any = {};

    fa:any = {};
    mo:any = {};
    rep:any = {};
    other:any = {};
    files:any = {};
    family_pic:any;
    
    saving = false;
    loading = false;
    edited = false;

    constructor(
          public _login: LoginService,
          public _router: Router,
          public api: ApiService,
          private storage: Storage
      ) {
          
      }

    async ngOnInit() {
        window.scroll({ 
                 top: 0, 
                 left: 0, 
                 behavior: 'smooth' 
              });

        this._login.getCommonData().then(rs => {
            this.filters = rs;

            this.filters.inst_levels = [
                {id: 'COLEGIO', desc: 'COLEGIO'},
                {id: 'PREGRADO', desc: 'PREGRADO'},
                {id: 'POSTGRADO', desc: 'POSTGRADO'}
            ];
        });

        var promises = [
            this.storage.get('appcode'),
            this.storage.get('period'),
            this.storage.get('codefam'),
        ];

        await Promise.all(promises).then(t => {
            this.period = t[1];

            if( t[0] ){
                this.appcode = t[0];
            }

            if( t[2] ){
                this.codefam = t[2];
            }
        });

        this.getInfo(this.appcode, this.codefam);
          
        //to avoid page change without saving
        this.edited = true;
    }

    validateParent(t:any){
        var msg = '';
        var sel = '';
        var obj:any = {};

        if( t == 'fa' ){
            obj = this.fa;
            sel = 'Padre';
        }
        else if( t == 'mo' ){
            obj = this.mo;
            sel = 'Madre';
        }
        else{
            obj = this.rep;
            sel = 'Representante Legal';
        }
        
        try{
            if(obj.lastname_1 == undefined || (obj.lastname_1 == '') ){
                throw 'Ingrese Apellido Paterno de '+sel;
            }

            if(obj.lastname_2 == undefined || (obj.lastname_2 == '') ){
                throw 'Ingrese Apellido Materno de '+sel;
            }

            if(obj.name == undefined || (obj.name == '') ){
                throw 'Ingrese Nombres Completos de '+sel;
            }

            if(obj.birthplace == undefined || (obj.birthplace == '') ){
                throw 'Ingrese País de '+sel;
            }

            if( (t != 'rep') && ( (obj.city == undefined) || (obj.city == '') ) ){
                throw 'Ingrese Ciudad de '+sel;
            }

            if(obj.dob == undefined || (obj.dob == '') ){
                throw 'Ingrese Fecha de Nacimiento de '+sel;
            }

            /*if(obj.nationality == undefined || (obj.nationality == '') ){
              throw 'Ingrese Ciudad de '+sel;
            }*/

            if(obj.doc_id == undefined || (obj.doc_id == '') ){
                throw 'Ingrese Identificación de '+sel;
            }

            /*if(obj.home_phone == undefined || (obj.home_phone == '') ){
              throw 'Ingrese Teléfono de Domicilio de '+sel;
            }*/

            if(obj.home_cell == undefined || (obj.home_cell == '') ){
                throw 'Ingrese Celular de '+sel;
            }

            if(obj.email == undefined || (obj.email == '') ){
                throw 'Ingrese Email de '+sel;
            }

        }catch(err){
            msg = err;
        }

        return msg;
    }

    handleInputChange(e, obj:any, ref:string) {
        //console.log(obj);
        var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
        obj[ref] = file;
        /*var reader = new FileReader();

        reader.onload = (_event) => { 
          obj[ref] = reader.result;
        }

        reader.readAsDataURL(file);*/
    }

    getInfo(appcode:any, codefam:any){
        if( appcode && codefam ){
            //appcode exist so try to get info
            this.loading = true;
            this.getDataFromServer(appcode, codefam).subscribe(x => {
                this.loading = false;
                //console.log(x);
                this.parseData(x);
            });
        }
    }

    getDataFromServer(appcode:any, codefam:any){
        var par_url = 'admission/step2';

        return this.api.getCallNoAuth(par_url, {code:appcode, codefam:codefam, period: this.period}).pipe(
                    mergeMap(data => {
                        let mydata:any = {status: false, message:'', data:[]};
                        mydata = data;
                        
                        if( mydata.status ){
                            return of(mydata.data);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    sendDataToServer(obj:any){
        var par_url = 'admission/step2';

        return this.api.postCallNoAuth(par_url, obj).pipe(
                    mergeMap(data => {
                        let mydata:any = {status: false, message:'', data:[]};
                        mydata = data;
                        //console.dir(mydata);
                        if( mydata.status ){
                            return of(mydata.data);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    parseData(data:any){
        if( data ){
            var tmp = {
                'live_together': data['adic']['live_together'],
                'live_with': data['adic']['live_with'],
                'fam_copece': data['adic']['fam_copece'],
                'civ_wed': this.parseDate(data['adic']['civ_wed']),
                'church_wed': this.parseDate(data['adic']['church_wed'])
            };

            this.other = {...this.other, ...tmp};

            //parents
            this.parseParent(this.fa, data['parents']['father']);
            this.parseParent(this.mo, data['parents']['mother']);
            this.parseRep(this.rep, data['rep']);

            //images
            if(this.appcode && data['family_pic']){
                this.family_pic = this.api.getBaseURL() + "admission/step2/family?code=" + this.appcode;
            }
        }
    }

    parseDate(str:any){
        let resp = null;
        let obj = this.momentjs(str);

        if( obj.isValid() ){
            resp = obj.format('YYYY-MM-DD');
        }

        return resp;
    }

    parseParent(obj:any, raw:any){
        //console.dir(raw);
        obj.lastname_1 = raw.lastname_1;
        obj.lastname_2 = raw.lastname_2;
        obj.name = raw.name;
        //obj.dob = this.momentjs(raw.dob).format('YYYY-MM-DD');
        obj.dob = this.parseDate(raw.dob);
        obj.type_id = raw.type_id;
        obj.birthplace = raw.birthplace;
        obj.city = raw.city;
        obj.doc_id = raw.doc_id;
        obj.civil_status = raw.civil_status;
        obj.school = raw.school;
        obj.instruction = raw.instruction;
        obj.title = raw.title;
        obj.job = raw.job;
        obj.job_title = raw.job_title;
        obj.job_address = raw.job_address;
        obj.job_phone = raw.job_phone;
        obj.home_address = raw.home_address;
        obj.main_st = raw.main_st;
        obj.home_phone = raw.home_phone;
        obj.religion = raw.religion;
        obj.email = raw.email;
        obj.home_cell = raw.home_cell;
    }

    parseRep(obj:any, raw:any){
        obj.lastname_1 = raw.lastname_1;
        obj.lastname_2 = raw.lastname_2;
        obj.name = raw.name;
        //obj.dob = this.momentjs(raw.dob).format('YYYY-MM-DD');
        obj.dob = this.parseDate(raw.dob);
        obj.type_id = raw.type_id;
        obj.birthplace = raw.birthplace;
        obj.doc_id = raw.doc_id;
        obj.title = raw.title;
        obj.job = raw.job;
        obj.job_phone = raw.job_phone;
        obj.home_address = raw.home_address;
        obj.home_cell = raw.home_cell;
        obj.home_phone = raw.home_phone;
        obj.email = raw.email;
        obj.relationship = raw.relationship;
        obj.nationality = raw.nationality;
    }

    saveAndNext(){
        /*console.log(this.fa);
        console.log(this.mo);
        console.log(this.other);*/

        var error:any = '';

        if( (this.fa.doc_id == undefined) && (this.mo.doc_id == undefined) ){
            this.showError('Debe ingresar la información completa al menos de padre o madre');
            return;
        }

        error = this.validateParent('fa');
        if( error == '' ){
            error = this.validateParent('mo');
        }

        if( error != '' ){
            this.showError(error);
            return;
        }

        if( (this.other.live_together == '') || (this.other.fam_copece == '') ){
            this.showError('Debe ingresar información de relación familiar');
            return;
        }

        if( (this.other.live_together == 'N') && ( (this.other.live_with == undefined) || (this.other.live_with == null) ) ){
            this.showError('Si los padres no viven juntos debe indicar con quien vive el aspirante');
            return;
        }

        if( this.other.live_with == 'OTRO' ){
            error = this.validateParent('rep');
        }

        if( error != '' ){
            this.showError(error);
            return;
        }

        let obj:any = {};

        if( this.appcode ){
            obj.code = this.appcode;
        }

        if( this.codefam ){
            obj.codefam = this.codefam;
        }

        obj.fa = this.fa;
        obj.mo = this.mo;
        obj.rep = this.rep;
        obj.other = this.other;
        obj.period = this.period;

        this.saving = true;

        this.sendDataToServer(obj).subscribe(x => {
            this.saving = false;
            //console.dir(x);

            if( x != false ){
                let codefam = x['codefam'];

                var batch = [];
                batch.push(this.uploadFamilyPic(this.appcode));

                this.saving = true;

                concat(...batch).subscribe({
                    complete: () => {
                        console.log('paso 2 completado');

                        var promises = [
                            this.storage.set('codefam', codefam)
                        ];

                        Promise.all(promises).then(x => {
                            this.saving = false;
                            this._router.navigate(['/paso_3']);
                        });
                    }
                });
            }
            else{
                Swal.fire('Error al guardar información', '', 'error');
            }
        });
    }

    showError(msg:string){
        Swal.fire(msg, '', 'error');
    }

    uploadFamilyPic(appcode:string){
        if( this.files['familypic'] ){
            var qst = 'admission/step2/family?code=' + appcode;
            return this.uploadFile(qst, this.files['familypic']);
        }
        else{
            return of(false);
        }
    }

    uploadFile(qst:string, file:any){
        let formData = new FormData();
        formData.append("file", file);
        
        return this.api.customPostCallNoAuth(qst, formData).pipe(
                    mergeMap(data => {
                        let mydata:any = {status: false, message:'', data:[]};
                        mydata = data;
                        
                        if( mydata.status ){
                            return of(mydata.data);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    openFile(url:string){
        window.open(url);
    }

    validateID(event: any){
        /*let allowed:any = [];
        allowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

        if( allowed.indexOf(event.key) < 0 ){
            return false;
        }

        return true;*/
        return /^[a-z0-9]+$/i.test(event.key);
    }

    validateNumber(event: any){
        let allowed:any = [];
        allowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

        if( allowed.indexOf(event.key) < 0 ){
            return false;
        }

        return true;
    }

}
