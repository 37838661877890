import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterCourse'
})
export class FilterCoursePipe implements PipeTransform {

    transform(items: any[], filter: Object): any {
        if (!items || !filter) {
            return items;
        }

        let keys = Object.keys(filter);
        //console.dir(keys);

        if( Array.isArray(keys) && (keys.length > 0) ){
            let k = keys[0];
            let cmp = parseInt(filter[k]);

            if( cmp == 1 ){
                return items.filter(item => parseInt(item[k]) < 2);
            }
            else if( cmp == 2 ){
                return items.filter(item => parseInt(item[k]) >= 2);
            }
            else{
              return items;
            }
        }
        else{
            return items;
        }

        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        //return items.filter(item => item.title.indexOf(filter.title) !== -1);
        //return items;
    }

}
