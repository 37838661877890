export class OfflineContainer {
        filters:any;
        data:any;
        last_update:string;

        constructor(){
            this.setLastUpdate();
        }

        public setLastUpdate(){
            let tmp = new Date();
            this.last_update = tmp.toISOString();
        }

        public setData(obj:any){
            this.data = obj;
            this.setLastUpdate();
        }

        public setFilters(obj:any){
            this.filters = obj;
            this.setLastUpdate();
        }

}