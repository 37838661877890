import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
//import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { LaddaModule } from 'angular2-ladda';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { OrderModule } from 'ngx-order-pipe';
import { ExportAsModule } from 'ngx-export-as';

import { FullComponent } from './layouts/full/full.component';
/*import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';*/

import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { setTheme } from 'ngx-bootstrap/utils';
setTheme('bs4');

import { Hivimarroutes } from './hivimar-routing';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { HivimarComponent } from '../hivimar/hivimar.component';

import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
/*import {CalendarModule} from 'primeng/calendar';*/
import {SliderModule} from 'primeng/slider';
import {MultiSelectModule} from 'primeng/multiselect';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';
import {ProgressBarModule} from 'primeng/progressbar';
import {InputTextModule} from 'primeng/inputtext';
import {PanelModule} from 'primeng/panel';
import {TabViewModule} from 'primeng/tabview';
import {FieldsetModule} from 'primeng/fieldset';

import { SignaturePadModule } from '@ng-plus/signature-pad';
import { ChartsModule } from 'ng2-charts';

import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { AgmDrawingModule } from '@agm/drawing';
import { LightboxModule } from 'ngx-lightbox';
import { BaseCrudComponent } from './base-crud/base-crud.component';


import { HomeComponent } from './home/home.component';
import { ColaboratorsComponent } from './colaborators/colaborators.component';


import { CalendarModule, CalendarDateFormatter } from 'angular-calendar';

import { SettingsComponent } from './settings/settings.component';

import { CommonComponent } from './layouts/common/common.component';
import { Step2Component } from './step2/step2.component';
import { Step3Component } from './step3/step3.component';
import { Step4Component } from './step4/step4.component';
import { Step5Component } from './step5/step5.component';
import { Step6Component } from './step6/step6.component';

import { MyFilterPipe } from '../delta/pipes/my-filter.pipe';
import { FilterCoursePipe } from '../delta/pipes/filter-course.pipe';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 1,
  wheelPropagation: true,
  minScrollbarLength: 20
};

/*registerLocaleData(localeEs);*/

@NgModule({
  declarations: [
    FullComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    HivimarComponent,
    BaseCrudComponent,
    SettingsComponent,
    HomeComponent,
    ColaboratorsComponent,
    CommonComponent,
    Step2Component,
    Step3Component,
    Step4Component,
    Step5Component,
    Step6Component,
    MyFilterPipe,
    FilterCoursePipe
  ],
  imports: [
    CommonModule,
    ChartsModule,
    BrowserModule,
    BrowserAnimationsModule,
    TableModule,
    MultiSelectModule,
    TabViewModule,
    FieldsetModule,
    ToastModule,
    PanelModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    HttpClientModule,
    NgbModule,
    RouterModule.forRoot(Hivimarroutes),
    PerfectScrollbarModule,
    //NgMultiSelectDropDownModule.forRoot(),
    DropdownModule,
    AgmCoreModule,
    AgmJsMarkerClustererModule,
    AgmDrawingModule,
    BsDatepickerModule.forRoot(),
    LaddaModule,
    SignaturePadModule,
    CalendarModule.forRoot(),
    TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
    OrderModule,
    ExportAsModule,
    LightboxModule
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    DatePipe
  ]/*,
  bootstrap: [AppComponent]*/
})
export class HivimarModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
    //return new TranslateHttpLoader(http);
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}