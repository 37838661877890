import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from '../global';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, of, throwError, from } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { OfflineContainer } from '../offlinecontainer';

//max time for api requests
const MAX_TIME = 180000;

@Injectable({
  providedIn: 'root'
})
export class ApiService {
	url:any;
	public reload_pic = new BehaviorSubject(false);
	public team_changed = new BehaviorSubject(false);

	public offline = new BehaviorSubject(false);

  	constructor(
  		private _router: Router,
  		private http: HttpClient,
  		public storage: Storage
  	) {
  		this.url = Global.url;
  	}

  	public getBaseURL(){
  		return this.url;
  	}

  	public getCall(qst, params?:any) {
		var resp:any = {status:false, message:'', data:[]};
		var extra = '';

        if( params != undefined ){
            Object.keys(params).forEach(k => {
                extra += '&' + k + '=' + params[k];
            });
        }

		return from(this.storage.get('token')).pipe(
				mergeMap(token => {
					const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`});
	    			return this.http.get(this.url+qst+'?dp=1'+extra, { headers })
	    				.pipe(
					  		mergeMap(data => {
		                      	let mydata:any = {status: '', objeto: [], mensaje: ''};
					      	  	mydata = data;

		                      	if( mydata.status == 'success' ){
					      			//return of(mydata.OBJETO);
					      			resp.status = true;
					      		}

					      		resp.message = mydata.mensaje;
					      		resp.data = mydata.objeto;

					      		return of(resp);
		                    }),
		                    timeout(MAX_TIME),
		                    catchError(data => {
		                    	resp['message'] = 'system error';
					            return of(resp);
		                    })
					  	);
				})
			);
	}

	public postCall(qst, obj) {
		var resp:any = {status:false, message:'', data:[]};
		return from(this.storage.get('token')).pipe(
				mergeMap(token => {
					const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`});
					//console.log('before post');
	    			return this.http.post(`${this.url}`+qst, obj, { headers })
	    				.pipe(
					  		mergeMap(data => {
		        				let mydata:any = {status: '', objeto: [], mensaje: ''};
					      		mydata = data;

					      		if( mydata.status == 'success' ){
					      			resp.status = true;
					      		}

								//resp.message = (mydata.mensaje==null)?"":mydata.mensaje;
								resp.message = mydata.mensaje;  
								resp.data = mydata.objeto;

					      		return of(resp);
		        			}),
		        			timeout(MAX_TIME),
		        			catchError(err => {
		        				resp['message'] = 'system error';
					            return of(resp);
					        })
					  );
				})
			);
	}

	public customPostCall(qst, obj) {
		let mydata:any = {message:'', data:[], status: false};

		return from(this.storage.get('token')).pipe(
				mergeMap(token => {
					const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept':  'application/json'});
					//console.log('before post');
	    			return this.http.post(this.url+qst+'?token='+token, obj, {})
	    				.pipe(
					  		mergeMap(data => {
		                      mydata = data;

		                      if( !mydata.status ){
		                      	  mydata.message = mydata.message.Error.msg;
		                      }

		                      return of(mydata);
		                    }),
		                    timeout(MAX_TIME),
		                    catchError(data => {
		                    	mydata.message = 'Error de sistema';
		                    	return of(mydata);
		                    })
					  	);
				})
			);
	}

	public persistData(key:string, val:any){
		return this.storage.set(key, val);
	}

	public getPeristedData(key:string){
		return this.storage.get(key);
	}

	public getBlobCall(qst) {
		return from(this.storage.get('token')).pipe(
				mergeMap(token => {
					const headers = new HttpHeaders({ 'Content-Type': 'application/json'});
	    			return this.http.get(this.url+qst+'?token='+token, { headers, responseType: 'blob' })
	    				.pipe(
					  		mergeMap(data => {
					  			//console.log(data);
					      		return of(data);
					      		//return of(true);
		        			}),
		        			timeout(MAX_TIME),
		        			catchError(err => {
					            return of(false);
					        })
					  );
				})
			);
	}

	public postBlobCall(qst, obj:any) {
		return from(this.storage.get('token')).pipe(
				mergeMap(token => {
					const headers = new HttpHeaders({ 'Content-Type': 'application/json'});
	    			return this.http.post(this.url+qst+'?token='+token, obj, { headers, responseType: 'blob' })
	    				.pipe(
					  		mergeMap(data => {
					  			//console.log(data);
					      		return of(data);
					      		//return of(true);
		        			}),
		        			timeout(MAX_TIME),
		        			catchError(err => {
					            return of(false);
					        })
					  );
				})
			);
	}

	public getCallNoAuth(qst, params?:any) {
		var resp:any = {status:false, message:'', data:[]};
		var extra = '';

        if( params != undefined ){
            Object.keys(params).forEach(k => {
                extra += '&' + k + '=' + params[k];
            });
        }

		return from(this.storage.get('token')).pipe(
				mergeMap(token => {
					const headers = new HttpHeaders({ 'Content-Type': 'application/json'});
	    			return this.http.get(this.url+qst+'?dp=1'+extra, { headers })
	    				.pipe(
					  		mergeMap(data => {
		                      	let mydata:any = {status: '', data: [], message: ''};
					      	  	mydata = data;

		                      	if( mydata.status ){
					      			//return of(mydata.OBJETO);
					      			resp.status = true;
					      		}

					      		resp.message = mydata.message;
					      		resp.data = mydata.data;

					      		return of(resp);
		                    }),
		                    timeout(MAX_TIME),
		                    catchError(data => {
		                    	resp['message'] = 'system error';
					            return of(resp);
		                    })
					  	);
				})
			);
	}

	public postCallNoAuth(qst, obj) {
		var resp:any = {status:false, message:'', data:{}};
		return from(this.storage.get('token')).pipe(
				mergeMap(token => {
					const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
					//console.log('before post');
	    			return this.http.post(`${this.url}`+qst, obj, { headers })
	    				.pipe(
					  		mergeMap(data => {
		        				return of(data);
		        			}),
		        			timeout(MAX_TIME),
		        			catchError(err => {
		        				resp['message'] = 'system error';
					            return of(resp);
					        })
					  );
				})
			);
	}

	public postBlobCallNoAuth(qst, obj:any) {
		return from(this.storage.get('token')).pipe(
				mergeMap(token => {
					const headers = new HttpHeaders({ 'Content-Type': 'application/json'});
	    			return this.http.post(this.url+qst, obj, { headers, responseType: 'blob' })
	    				.pipe(
					  		mergeMap(data => {
					  			//console.log(data);
					      		return of(data);
					      		//return of(true);
		        			}),
		        			timeout(MAX_TIME),
		        			catchError(err => {
					            return of(false);
					        })
					  );
				})
			);
	}

	public customPostCallNoAuth(qst, obj) {
		let mydata:any = {message:'', data:[], status: false};

		return from(this.storage.get('token')).pipe(
				mergeMap(token => {
					const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept':  'application/json'});
					//console.log('before post');
	    			return this.http.post(this.url+qst, obj, {})
	    				.pipe(
					  		mergeMap(data => {
		                      mydata = data;

		                      if( !mydata.status ){
		                      	  mydata.message = mydata.message.Error.msg;
		                      }

		                      return of(mydata);
		                    }),
		                    timeout(MAX_TIME),
		                    catchError(data => {
		                    	mydata.message = 'Error de sistema';
		                    	return of(mydata);
		                    })
					  	);
				})
			);
	}

	public saveOfflineData(key:string, obj:any){
		let tmp = new OfflineContainer();
		tmp.setData(obj);
		return this.persistData(key, tmp);
	}

	public getOfflineData(key:string){
		return this.getPeristedData(key).then(x => {
			if( (x == undefined) || (x == null) ){
				return null;
			}
			else{
				let tmp = new OfflineContainer();
				tmp.data = x.data;
				tmp.last_update = x.last_update;
				return tmp;
			}
			
		});
	}

	public isReachable() {
		/**
		   * Note: fetch() still "succeeds" for 404s on subdirectories,
		   * which is ok when only testing for domain reachability.
		   *
		   * Example:
		   *   https://google.com/noexist does not throw
		   *   https://noexist.com/noexist does throw
		*/
		/*var url = 'https://hivi.hivimar.com/';
		return fetch(url, { method: 'HEAD', mode: 'no-cors' })
		    .then(function(resp) {
		      return resp && (resp.ok || resp.type === 'opaque');
		    })
		    .catch(function(err) {
		      console.warn('[conn test failure]:', err);
		    });*/
		//for now to avoid this check we always send true
		const myPromise = new Promise((resolve, reject) => {
		  resolve(true);
		});

		return myPromise;
	}

}
