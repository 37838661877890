import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BlankComponent } from './hivimar/layouts/blank/blank.component';
import { CommonComponent } from './hivimar/layouts/common/common.component';
import { LoginService } from './services/authentication/login.service';
import { LoginGuard } from './services/login.guard';

export const Approutes: Routes = [
  {
    path: '',
    component: BlankComponent,
    children: [
      { path: '', redirectTo: '/authentication/login', pathMatch: 'full' },
      {
        path: 'authentication',
        canActivate: [LoginGuard],
        loadChildren:
          () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
      }
    ]
  }/*,
  {
    path: '',
    component: CommonComponent,
    children: [
        {
          // path: 'tracking/:id',
          // component: LogiclientComponent,
          // data: {
          //     title: 'Mi Pedido'
          // }
      }
    ]
  }*/
];
