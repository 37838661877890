import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/authentication/login.service';
import { ApiService } from '../../services/main/api.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Observable, BehaviorSubject, of, throwError, from, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.css']
})
export class Step2Component implements OnInit {

    momentjs: any = moment;

    appcode:any;
    codefam:any;
    period:any;
    filters:any = {};

    quiz:any = [];
    
    saving = false;
    loading = false;
    edited = false;

    constructor(
          public _login: LoginService,
          public _router: Router,
          public api: ApiService,
          private storage: Storage
      ) {
          
      }

    async ngOnInit() {
          window.scroll({ 
                     top: 0, 
                     left: 0, 
                     behavior: 'smooth' 
                  });

          this._login.getCommonData().then(rs => {
              this.filters = rs;
          });

          var promises = [
              this.storage.get('appcode'),
              this.storage.get('period'),
              this.storage.get('codefam'),
          ];

          await Promise.all(promises).then(t => {
              this.period = t[1];

              if( t[0] ){
                  this.appcode = t[0];
              }

              if( t[2] ){
                  this.codefam = t[2];
              }
          });

          this.getInfo(this.appcode, this.codefam);
          
          //to avoid page change without saving
          this.edited = true;
    }

    handleInputChange(e, obj:any, ref:string) {
        //console.log(obj);
        var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
        obj[ref] = file;
        /*var reader = new FileReader();

        reader.onload = (_event) => { 
          obj[ref] = reader.result;
        }

        reader.readAsDataURL(file);*/
    }

    getInfo(appcode:any, codefam:any){
        if( appcode && codefam ){
            //appcode exist so try to get info
            this.loading = true;
            this.getDataFromServer(appcode, codefam).subscribe(x => {
                this.loading = false;
                //console.log(x);
                this.parseData(x);
            });
        }
    }

    getDataFromServer(appcode:any, codefam:any){
        var par_url = 'admission/step3';

        return this.api.getCallNoAuth(par_url, {code:appcode, codefam:codefam, period: this.period}).pipe(
                    mergeMap(data => {
                        let mydata:any = {status: false, message:'', data:[]};
                        mydata = data;
                        
                        if( mydata.status ){
                            return of(mydata.data);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    sendDataToServer(obj:any){
        var par_url = 'admission/step3';
        //console.dir(obj);return;

        return this.api.postCallNoAuth(par_url, obj).pipe(
                    mergeMap(data => {
                        let mydata:any = {status: false, message:'', data:[]};
                        mydata = data;
                        //console.dir(mydata);
                        if( mydata.status ){
                            return of(true);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    parseData(data:any){
        //console.log(data);
        if( data ){
            if( Array.isArray(data['quiz']) ){
                //this.quiz = data['quiz'].filter(q => q.pos > 1);
                this.quiz = data['quiz'];
            }
        }
    }

    parseParent(obj:any, raw:any){
        //console.dir(raw);
        obj.lastname_1 = raw.lastname_1;
        obj.lastname_2 = raw.lastname_2;
        obj.name = raw.name;
        obj.dob = this.momentjs(raw.dob).format('YYYY-MM-DD');
        obj.type_id = raw.type_id;
        obj.birthplace = raw.birthplace;
        obj.doc_id = raw.doc_id;
        obj.civil_status = raw.civil_status;
        obj.school = raw.school;
        obj.instruction = raw.instruction;
        obj.title = raw.title;
        obj.job = raw.job;
        obj.job_title = raw.job_title;
        obj.job_address = raw.job_address;
        obj.job_phone = raw.job_phone;
        obj.home_address = raw.home_address;
        obj.home_phone = raw.home_phone;
        obj.religion = raw.religion;
    }

    saveAndNext(){
        let obj:any = {};

        if( this.appcode ){
            obj.code = this.appcode;
        }

        if( this.codefam ){
            obj.codefam = this.codefam;
        }

        obj.quiz = this.quiz;
        obj.period = this.period;
        
        this.saving = true;

        this.sendDataToServer(obj).subscribe(x => {
            this.saving = false;
            //console.dir(x);

            if( x != false ){
                this.saving = false;
                this._router.navigate(['/paso_4']);
            }
            else{
                Swal.fire('Error al guardar información', '', 'error');
            }
        });
    }

    showError(msg:string){
        Swal.fire(msg, '', 'error');
    }

    uploadFile(qst:string, file:any){
        let formData = new FormData();
        formData.append("file", file);
        
        return this.api.customPostCallNoAuth(qst, formData).pipe(
                    mergeMap(data => {
                        let mydata:any = {status: false, message:'', data:[]};
                        mydata = data;
                        
                        if( mydata.status ){
                            return of(mydata.data);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    openFile(url:string){
        window.open(url);
    }

}
