import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { LoginService } from '../../services/authentication/login.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

    translations:any;
    available_languages:any;
    currlang:any;
    version:any;

  	constructor(
  		public srv: LoginService,
  		public _router: Router,
      public translate: TranslateService
  	) { }

  	ngOnInit() {
          //console.log(environment.version);
          //this.version = environment.version;
          this.getTranslations();

          //console.log(this.translate.currentLang);
          this.currlang = this.translate.currentLang;
          //console.log(this.translate);

          this.translate.onLangChange.subscribe(() => {
              this.getTranslations();
          });

          this.version = environment.version;
  	}

  	logout(){
  		Swal.fire({
              title: this.translations.logout_msg,
              text: '',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: this.translations.cancel,
              confirmButtonText: this.translations.ok
          }).then((result) => {
              if (result.value) {
              	//console.log(this.srv);
              	this.srv.clearData().then(() => {
              		this._router.navigate(['/authentication/login']);
              	});
              }
          });
  	}

    getTranslations(){
        this.translate.getTranslation(this.translate.currentLang)
          .subscribe((translations) => {
                this.translations = translations;
          });
    }

    setLang(str:any){
        //console.log(str);
        this.translate.use(str);
    }

    async checkUpdates(){
        Swal.fire({
              title: this.translations.update_confirm,
              text: '',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: this.translations.cancel,
              confirmButtonText: this.translations.ok
          }).then((result) => {
              if (result.value) {
                  window.location.reload(true);
              }
          });
    }

}
