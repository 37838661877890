import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from '../global';
import { Router, Params, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ReturnStatement } from '@angular/compiler';
import { Observable, from, of } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import * as moment from 'moment-timezone';

//max time for api requests
const MAX_TIME = 60000;
const TOKEN_KEY = 'token';
const REFRESHTOKEN_KEY = 'refresh';
const PERMISSIONS_KEY = 'permissions';
const TOKEN_EXPIRE_KEY = '';
const TOKEN_EXPIRES_PERIOD = 20;

@Injectable({
  providedIn: 'root'
})

export class LoginService implements CanActivate {

  private url: any;
  private token: any;
  public cont: number;
  momentjs: any = moment;

  pages = [];

  constructor(
    private _router: Router,
    private _http: HttpClient,
    private storage: Storage,
    private route: ActivatedRoute
  ) {
      this.url = Global.url;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      var page = state.url.replace('/', '');
      //console.log(page);
      //return true;

      var promises = [
          this.storage.get('appcode'),
          this.storage.get('appnew')
      ];

      return Promise.all(promises).then(t => {
          if( t[0] ){
              //console.log('case logged');
              return true;
          }
          else if( t[1]  && (page == 'home')){
              //console.log('case page home');
              return true;
          }
          else if( t[1]){
              console.log('case new app but no appcode yet');
              this._router.navigate(['/home']);
              return true;
          }
          else{
              this._router.navigate(['/authentication/login']);
              return false;
          }
          
      });
  }

  public login(data: any) {
      const params = JSON.stringify(data);
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this._http.post(`${this.url}admission/validateID`, params, { headers });
  }

  public logout(){
      return this.storage.clear();
  }

  public getInfo(){
      return this.storage.get('userinfo');
  }

  public getSystemInfo(){
      return this.storage.get('sys');
  }

  public getTeam(){
      return this.storage.get('subsidiaryId');
  }

  public setTeam(val){
      return this.storage.set('team', val);
  }

  public clearData(){
      return this.storage.clear();
  }

  public getCommonData(){
      return this.storage.get('common');
  }

}
