import { Component, OnInit } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
	public psconfig: PerfectScrollbarConfigInterface = {};
	menu:any;

	constructor() { }

	ngOnInit() {
		this.menu = [
			{name:'Alimentación', desc:'Reserva almuerzos y meriendas', icon:'fas fa-utensils', url:''},
			{name:'Certificado laboral', desc:'Consulta e imprime tu certificado laboral', icon:'fas fa-certificate', url:''},
			{name:'Rol de pago', desc:'Consulta tus roles actual y pasados', icon:'fas fa-money-check-alt', url:'/payrol'},
			{name:'Mi perfil', desc:'Pefil de usuario', icon:'fas fa-qrcode', url:''},
			{name:'Permisos', desc:'Permisos', icon:'fas fa-hospital-user', url:''},
			{name:'Anticipos', desc:'Anticipos', icon:'fas fa-money-bill-alt', url:''},
			{name:'Vacaciones', desc:'Vacaciones', icon:'fas fa-umbrella-beach', url:''},
			{name:'Comunicados', desc:'Comunicados', icon:'fas fa-bell', url:''},
			{name:'Beneficios', desc:'Beneficios', icon:'fas fa-gift', url:''},
			{name:'Prestamos', desc:'Prestamos', icon:'fas fa-hand-holding-usd', url:''},
			{name:'Viáticos', desc:'Viáticos', icon:'fas fa-coins', url:''}
		];

	}

}
