import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { ColaboratorsComponent } from './colaborators/colaborators.component';
import { SettingsComponent } from './settings/settings.component';

import { Step2Component } from './step2/step2.component';
import { Step3Component } from './step3/step3.component';
import { Step4Component } from './step4/step4.component';
import { Step5Component } from './step5/step5.component';
import { Step6Component } from './step6/step6.component';

import { LoginService } from '../services/authentication/login.service';

export const Hivimarroutes: Routes = [
  {
    path: '',
    component: FullComponent,
    //canActivate: [LoginService],
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'home',
        component: ColaboratorsComponent,
        canActivate: [LoginService],
        data: {
          title: '1. Aspirante',
          urls: [
            /*{ title: 'Mapas', url: '' },
            { title: 'Mapas' }*/
          ]
        }
      },
      {
        path: 'paso_2',
        component: Step3Component,
        canActivate: [LoginService],
        data: {
          title: '2. Familia',
          urls: [
            { title: 'Aspirante', url: '/home' },
            { title: 'Familia'},
          ]
        }
      },
      {
        path: 'paso_3',
        component: Step2Component,
        canActivate: [LoginService],
        data: {
          title: '3. Información Adicional',
          urls: [
            { title: 'Aspirante', url: '/home' },
            { title: 'Familia', url: '/paso_2' },
            { title: 'Información Adicional' }
          ]
        }
      },
      {
        path: 'paso_4',
        component: Step4Component,
        canActivate: [LoginService],
        data: {
          title: '4. Datos Familiares',
          urls: [
            { title: 'Aspirante', url: '/home' },
            { title: 'Familia', url: '/paso_2' },
            { title: 'Información Adicional', url: '/paso_3' },
            { title: 'Datos Familiares' }
          ]
        }
      },
      {
        path: 'paso_5',
        component: Step5Component,
        canActivate: [LoginService],
        data: {
          title: '5. Convivencia',
          urls: [
            { title: 'Aspirante', url: '/home' },
            { title: 'Familia', url: '/paso_2' },
            { title: 'Información Adicional', url: '/paso_3' },
            { title: 'Datos Familiares', url: '/paso_4' },
            { title: 'Convivencia'}
          ]
        }
      },
      {
        path: 'paso_6',
        component: Step6Component,
        canActivate: [LoginService],
        data: {
          title: '6. Referencias',
          urls: [
            { title: 'Aspirante', url: '/home' },
            { title: 'Familia', url: '/paso_2' },
            { title: 'Información Adicional', url: '/paso_3' },
            { title: 'Datos Familiares', url: '/paso_4' },
            { title: 'Convivencia', url: '/paso_5' },
            { title: 'Referencias' }
          ]
        }
      }
      //end
    ]
  },
];
